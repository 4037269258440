
export const clienteEmpty = () => {
    return {
        Cliente: {
            Estado: {
                Descricao: ''
            },
            Endereco: [{
                Cidade: {

                },
                Bairro: '',
                Logradouro: '',

            }],
            Contato: [{
                TipoContato: 0,
                Tratamento: 0
            }],

        }
    };
}

export const veiculoEmpty = () => {
  return {
    TipoLeilao: null,
    TipoVeiculo: '',
    AnoMod: '',
    Modelo: {
      Descricao: '',
      Marca: { Descricao: '' }
    },
    Marca: {},
    Opcional: []
  }
}
