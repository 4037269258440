<template>
  <section class="card" id="ava">
    <div class="row h-100">
      <div class="col h-100">
        <div class="card-body">
          <div class="wizard-container">
            <input type="hidden" v-model="obj.Id" />
            <form-wizard
                         color="#08c"
                         ref="wizard"
                         @on-complete="salvar()"
                         back-button-text="Anterior"
                         next-button-text="Próximo"
                         finish-button-text="Salvar"
                         title=""
                         subtitle="">
              <tab-content title="Cliente" icon="fa fa-user" :before-change="validarCliente">
                <div class="row justify-content-lg-center">
                  <div class="col-lg-7">
                    <div class="validation-message" v-if="$root.errors.length">
                      <ul style="display: block;">
                        <template v-for="p in $root.errors">
                          <li :key="p">
                            <label  id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>

                          </li>
                        </template>
                        
                      </ul>
                    </div>
                    <pessoa-partial :params="obj">
                    </pessoa-partial>
                  </div>
                </div>
                
                
              </tab-content>
              <tab-content title="Veículo" icon="fa fa-car" :before-change="() => $root.validarVeiculo(obj.Veiculo)">
                <div class="row justify-content-lg-center">
                  <div class="col-lg-7">
                    <div class="validation-message" v-if="$root.errors.length">
                      <ul style="display: block;">
                        <template v-for="p in $root.errors">
                          <li :key="p">
                            <label id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>

                          </li>
                        </template>
                        
                      </ul>
                    </div>
                    <div>
                      <veiculo-create-partial :obj="obj" :attrsVeiculo="attrsVeiculo"/>
                    </div>
                  </div>
                </div>
                
              </tab-content>
              <tab-content title="Checklist" icon="fa fa-clipboard-check" :before-change="validarChecklist">
                <div class="row justify-content-lg-center">
                  <div class="col-lg-7">
                    <div class="validation-message" v-if="$root.errors.length">
                  <ul style="display: block;">
                    <template  v-for="p in $root.errors">
                      <li :key="p">
                        <label id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>
                      </li>
                    </template>
                    
                  </ul>
                </div>
                <div>
                  <div>
                    <h4 class="text-primary">
                      Checklist
                    </h4>

                    <div class="row form-group ml-0 mr-0 row-opcionais">
                      <template v-for="checklist in checklists">
                        <blockquote :key="checklist.Id" class="col-lg-3 primary b-thin mb-3 pl-2">
                          <div class="form-group">
                            <div class="checkbox-custom checkbox-primary">
                              <input type="checkbox"
                                     v-on:change="changeChecklist(checklist)"
                                     :value="checklist" :id="'checklist-'+checklist.ChecklistId"
                                     v-model="obj.Checklist">
                              <label :for="'checklist-'+checklist.ChecklistId" class="d-block">{{ checklist.Descricao }} </label>

                            </div>
                          </div>
                          <div class="form-group">
                            <div class="row">
                              <div class="col-6">
                                <select v-model="checklist.StatusConservacao"
                                        class="form-control form-control-sm"
                                        v-on:change="changeStatusConservacao(checklist)">
                                  <template v-for="(i,$index) in statusConservacao">
                                    <option :value="$index" :key="$index">
                                      {{i}}
                                    </option>
                                  </template>


                                </select><span v-if="obj.Checklist.some(c => c == checklist)" class="text-danger conservacao-required"> *</span>
                              </div>
                              <div class="col-6">
                                <input type="text" v-model="checklist.Observacao" placeholder="Observação..." class="form-control form-control-sm" />
                              </div>
                            </div>
                          </div>
                        </blockquote>
                      </template>
                    </div>
                    <div class="form-divider"></div>
                    <h4 class="text-primary">
                      Serviços a executar
                      <button title="Incluir novo serviço"
                              type="button"
                              class="novo bt nbtn-sm btn-default ml-1"
                              @click="obj.ServicoAvaliador.push({ ServicoId: 0, Valor: 0, Servico: { Id: 0, Descricao: '',ClassificacaoServico: 0, ValorPadrao:0} })">
                        <i class="fas fa-plus" style="color: inherit;"></i>
                      </button>
                    </h4>
                    <div class="row form-group ml-0 mr-0">
                      <div class="col-lg-12">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th style="width:90px">Código</th>
                              <th>Descrição</th>
                              <th style="width:300px">Classificação</th>
                              <th style="width:150px">Valor</th>
                              <th style="width:70px"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(item) in obj.ServicoAvaliador">
                              <tr :key="item.Id">
                                <td>
                                  <input type="text" class="form-control form-control-sm" v-model="item.Servico.Id" disabled="disabled" />
                                </td>
                                <td>
                                  <v-select v-model="item.Servico"
                                            :options="servicos"
                                            @search="(search) => buscarServico(search)"
                                            :filterable="false"
                                            label="Descricao"
                                            append-to-body
                                            @input="(val) => changeServico(val,item)"
                                            taggable push-tags><span slot="no-options">Busca não encontrada</span></v-select>
                                </td>
                                <td>
                                  <select v-model="item.Servico.ClassificacaoServico"
                                          :disabled="item.Servico.Id > 0"
                                          class="form-control form-control-sm">
                                    <template v-for="(i,$index) in classificacaoServico">
                                      <option :value="$index" :key="$index">{{i}}</option>
                                    </template>
                                  </select>
                                </td>
                                <td>
                                  <money v-model="item.Valor" class="form-control form-control-sm"></money>
                                </td>
                                <td>
                                  <button title="Excluir servico" type="button" class="novo btn btn-sm btn-danger" @click="obj.ServicoAvaliador.splice(obj.ServicoAvaliador.indexOf(item),1)">
                                    <i class="fas fa-trash" style="color: inherit;"></i>
                                  </button>
                                </td>
                              </tr>

                            </template>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="3"><b class="pull-right">Total</b></td>
                              <td><money :value="Total" class="form-control form-control-sm" :disabled="true"></money></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div class="form-divider"></div>
                    <div class=" row form-group ml-0 mr-0">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="col-form-label">Tabela REF. FIPE</label><span class="text-danger"> *</span>
                          <input type="text" class="form-control form-control-sm" :disabled="true" v-model="obj.TabelaREFFipe" />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="col-form-label">Valor FIPE</label><span class="text-danger"> *</span>
                          <money v-model="obj.ValorFipe"
                                 class="form-control form-control-sm"
                                 id="ValorFipe"
                                 name="ValorFipe" :disabled="true"></money>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="col-form-label">Valor pretendido(cliente)</label>
                          <money v-model="obj.ValorPretendido"
                                 class="form-control form-control-sm"
                                 id="ValorPretendido"
                                 name="ValorPretendido"></money>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="col-form-label">Valor Avaliado</label>
                          <money v-model="obj.ValorAvaliado"
                                 class="form-control form-control-sm"
                                 id="ValorAvaliado"
                                 name="ValorAvaliado"></money>
                        </div>
                      </div>
                    </div>
                    <div class="form-divider"></div>
                    <h4 class="text-primary">
                      Fotos
                    </h4>
                    <div class="row form-group ml-0 mr-0">
                      <template v-for="f in obj.files">
                        <blockquote class="info b-thin col-lg-3  mb-3" :key="f.src">
                          <div class="row">
                            <div class="col-5">

                              <img :src="f.src" class="w-100" />
                            </div>
                            <div class="col-7">
                              <div class="form-group">
                                <label class="col-form-label">{{f.FileNameAbrv}}</label>
                                <input type="text" class="form-control form-control-sm" placeholder="Descrição..." v-model="f.Descricao" />
                              </div>
                            </div>
                          </div>
                        </blockquote>

                      </template>
                    </div>
                    <input type="file" id="files" multiple @change="changeFiles()" accept="image/*" />
                    <div class="form-divider"></div>
                    <h4 class="text-primary">
                      Observação
                    </h4>
                    <div class="row form-group ml-0 mr-0">
                      <div class="col">
                        <div class="form-group">
                          <textarea type="text" rows="6" class="form-control form-control-sm" v-model="obj.Observacao">
                                                </textarea>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                  </div>
                </div>
              </tab-content>
              <tab-content title="Confirmação" icon="fa fa-check-circle">
                <div class="row justify-content-lg-center">
                  <div class="col-lg-7">
                    <div class="validation-message" v-if="$root.errors.length">
                      <ul style="display: block;">
                        <template v-for="p in $root.errors">
                          <li :key="p">
                            <label  id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>

                          </li>
                        </template>
                        
                      </ul>
                    </div>
                    <div>
                      <detalhes :params="{avaliacao: obj}"></detalhes>
                    </div>
                  </div>
                </div>
              </tab-content>

            </form-wizard>
          </div>
          <div class="wizard-footer row justify-content-lg-center">
            <div class="col-lg-7">
              <button type="button" class="pull-left btn btn-sm btn-default" @click="$refs.wizard.prevTab(); nextButton = 'Próximo'; scrollToTop()">Anterior</button>
              <button type="button" class="pull-right btn btn-sm btn-primary" @click="$refs.wizard.nextTab(); $refs.wizard.isLastStep ? nextButton = 'Concluir' : nextButton = 'Próximo';scrollToTop()">{{ nextButton }}</button>
            </div>
            
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import api from '@/services/api.js';
import pessoaPartial from '@/components/PessoaPartial.vue';
import veiculoPartial from '@/components/VeiculoPartial.vue';
import detalhes from '@/views/AvaliacaoDetalhes.vue';
import { classificacaoServico, statusConservacao } from '@/services/enums.js';
  import { veiculoEmpty } from '@/models/index.js';
  import { prepararVeiculo } from '@/services/helpers.js';
  

//AVALIACAO - CREATE
export default {

    components: {
        'pessoa-partial': pessoaPartial,
    'detalhes': detalhes,
        'veiculo-create-partial':veiculoPartial
    },
    data() {
      const that = this;
      return {
            attrsVeiculo: { opcionais: [], cor: [], combustivel: [], transmissao: [], carroceria: []},
            selecionado: '',
            clone: '',
            loading: false,
            nextButton: 'Próximo',
            obj: {
                Cliente: {
                    Estado: {
                        Descricao: ''
                    },
                    Endereco: [
                        {
                            Cidade: {

                            }
                        }],
                    Contato: [{ TipoContato: 0, Tratamento: 0 }],

                },
                ServicoAvaliador: [],
                Veiculo: veiculoEmpty(),
                TabelaREFFipe: '',
                ValorFipe: '',
                Opcional: [],
                Checklist: [],
                files: []
            },
            cidades: [],
            classificacaoServico: classificacaoServico,
            statusConservacao: statusConservacao,

            checklists: [],//checklists,
            servicos: [],//servicos,
            estados: [],//estados,
        };
    },
    computed: {
        Total: function () {
            return this.obj.ServicoAvaliador.reduce((a, b) => a + b.Valor || 0, 0).toFixed(2);
        }
    },
    methods: {
      scrollToTop: function () {

        $('.wizard-container').scrollTop(0);

      },
      validarChecklist: function () {
          const obj = this.obj;
          this.$root.errors = [];

          for (let i in obj.Checklist) {
              let checklist = obj.Checklist[i];
              if (typeof (checklist.StatusConservacao) === 'undefined') {
                  this.$root.errors.push(`Selecionar a conservação do checklist "${checklist.Descricao}"`)
              }
          }
          for (let i in obj.ServicoAvaliador) {
              let servico = obj.ServicoAvaliador[i];
              if (typeof (servico.Servico.ClassificacaoServico) === 'undefined') {
                  this.$root.errors.push(`Campo "Classificação" dos serviços é obrigatório`)
              }
              if (!servico.Servico.Descricao) {
                  this.$root.errors.push(`Campo "Descrição" dos serviços é obrigatório`)
              }
          }
          return this.$root.isValid();

      },
      validarCliente: function () {
          const cliente = this.obj.Cliente;
          this.$root.errors = [];


          if (!cliente.Nome || !cliente.Nome.length) {
              this.$root.errors.push('Campo "Nome" é obrigatório')
          }
          if (cliente.CpfCnpj && !validarCpfCnpj(cliente.CpfCnpj)) {
              this.$root.errors.push('Campo "CPF CNPJ" inválido')
          }
          if (cliente.RGIE && cliente.RGIE.length < 9) {
              this.$root.errors.push('Campo "RG / Inscrição estadual" inválido')
          }
          if (!cliente.Contato.length) {
              this.$root.errors.push('Digite ao menos 1 contato do cliente')
          }
          else {
              for (let i in cliente.Contato) {
                  let contato = cliente.Contato[i];
                  if (!contato.TratarCom || !contato.TratarCom.length) {
                      this.$root.errors.push('Campo "Tratar com" dos contatos é obrigatório')
                  }
                  if (!contato.Telefone || !contato.Telefone.length) {
                      this.$root.errors.push('Campo "Número" dos contatos é obrigatório')
                  }
              }
          }

          return this.$root.isValid();
      },
      handleValidarVeiculo: function () {
        let result = this.$root.validarVeiculo(this.obj.Veiculo);

        if (!result) {
          this.scrollToTop();
        }

        return result;
      },
      

      salvar: function () {
          this.$root.errors = [];

          //Ajuste dos campos para view model
          let obj = this.obj;
          let that = this;

        prepararVeiculo(obj.Veiculo);
        
          //Salvo a avaliação, sem as imagens
          api
              .post('/avaliacoes/create', obj)
              .then(response => {

                    
                  //Se salvou a avaliacao, vamos subir as imagens agora
                  if (response.data.success) {
                      if (files.files.length) {
                          let formData = new FormData();
                          for (var i = 0; i < files.files.length; i++) {
                              let file = files.files[i];
                              if (file.size) {
                                  formData.append('files[' + i + ']', file);
                              }
                          }
                          const descricao = JSON.stringify(obj.files.map(f => { return { FileName: f.FileName, Descricao: f.Descricao } }));


                          formData.append('id', response.data.Id);
                          formData.append('descricao', descricao);

                          api
                              .post('/avaliacoes/uploadfotoavaliacao', formData, {
                                  headers: {
                                      'Content-Type': 'multipart/form-data'
                                  }
                              });
                      }
                      that.$router.push('/avaliacoes');
                  }
                  else {
                      that.$root.errors = response.data.errors;
                  }
              });
      },
        

      changeFiles: function () {
          let that = this;
          let objFiles = this.obj.files;
          let readers = [];

          for (let i in files.files) {
              let file = files.files[i];
              if (file.size) {
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      objFiles.push({
                          FileName: file.name,
                          src: e.target.result,
                          FileNameAbrv: file.name.length > 16 ? file.name.substring(0, 13) + '...' : file.name
                      });
                  };
                  reader.readAsDataURL(file);
              }
          }
      },
      changeStatusConservacao: function (checklist) {
          if (!this.obj.Checklist.some(c => c === checklist)) {
              this.obj.Checklist.push(checklist);
          }
      },
      changeChecklist: function (checklist) {
          if (!this.obj.Checklist.some(c => c === checklist)) {
              checklist.StatusConservacao = ''
          }
      },
      changeServico: function (val, servicoAvaliacao) {
          if (val) {
              if (typeof (val) === 'string') {
                  servicoAvaliacao.Servico = { Id: 0, ClassificacaoServico: 0, Descricao: val, ValorPadrao: 0 };
                  servicoAvaliacao.Valor = 0;
              }
              else {
                  if (!val.ClassificacaoServico) {
                      val.ClassificacaoServico = 0;
                  }
                  if (!val.Id) {
                      val.Id = 0
                  }
                  if (!val.ValorPadrao) {
                      val.ValorPadrao = 0;
                  }
                  servicoAvaliacao.Servico = val;
                  servicoAvaliacao.Valor = val.ValorPadrao;
              }
          }
      },
      buscarServico: function (search) {
          this.handleBuscarServico(search, this);
      },
      handleBuscarServico: _.debounce((search, that) => {
        if (search) {
          api.get(`/configuracoes/buscarservico?q=${escape(search)}`)
                .then(response => {
                    that.servicos = response.data;
                });
          }
        }, 350),
      updateAttr: function ($event, prop) {
          this.obj[prop] = $($event.target).find('[value="' + $event.target.value + '"]').text();
      }
    },
    mounted: function () {
        this.$nextTick(function () {

        });
    },
    beforeMount: function () {

        let that = this;
        api.get('/avaliacoes/create').then(function (response) {
          let { opcionais, checklists, cor, combustivel, transmissao, carroceria } = response.data;
          that.checklists = checklists;
      
          that.attrsVeiculo.opcionais = opcionais;
          that.attrsVeiculo.cor = cor;
          that.attrsVeiculo.combustivel = combustivel;
          that.attrsVeiculo.transmissao = transmissao;
          that.attrsVeiculo.carroceria = carroceria;
          

        });

    }
};
</script>

<style scoped>
  #ava .wizard-icon-container, #ava .wizard-progress-bar {
    background-color: #08c !important
}

#ava .wizard-icon-circle {
    border-color: #08c !important
}

#ava .stepTitle, #ava .wizard-progress-bar {
    color: #08c !important
}

#ava .wizard-progress-with-circle {
    z-index: 98 !important
}

#ava .wizard-progress-bar {
    z-index: 99 !important
}

#ava header.card-header {
    display: none !important
}

#ava .vue-form-wizard .wizard-nav-pills {
    background: #f6f6f6 !important;
    border-radius: 5px 5px 0 0 !important;
    border-bottom: 1px solid #dadada !important;
    padding: 18px 0 !important
}

#ava .vue-form-wizard.md .wizard-icon-circle {
    z-index: 100 !important
}

#ava .vue-form-wizard.md .wizard-icon-circle {
    z-index: 101 !important
}

#ava .vue-form-wizard.md .wizard-nav-pills > li.active > a .wizard-icon {
    z-index: 101 !important
}

#ava .vue-form-wizard.md .wizard-navigation .wizard-progress-with-circle {
    top: 44px !important;
    height: 3px !important
}

#ava .vue-form-wizard .wizard-card-footer {
    padding: 0 !important
}

#ava .vue-form-wizard .wizard-tab-content {
    padding: 1.25rem !important
}

#ava .vue-form-wizard.md .wizard-nav-pills > li.active > a .wizard-icon {
    font-size: 20px !important
}

.vue-form-wizard.md .wizard-icon-circle {
    width: 41px !important;
    height: 41px !important;
    font-size: 18px !important
}

#ava .vue-form-wizard {
    height: 100%
}

    #ava .vue-form-wizard .wizard-card-footer {
        display: none !important
    }

#ava .wizard-container {
    height: calc(100% - 68px) !important;
    overflow-y: auto !important
}

#ava .wizard-footer {
    height: 31px !important;
    padding: 18px 18px 0 18px !important;
    border-top: 1px solid #dadada !important
}

#ava.card .card-body {
    padding: 0 !important
}

#ava .v-select .dropdown-menu {
    max-height: 175px !important
}

#ava .conservacao-required {
    position: absolute !important;
    top: -5px !important;
    right: 4px !important
}

</style>
