<template>
  <div>
    <h4 class="text-primary">Veículo</h4>
    <div class="row form-group ml-0 mr-0">
      <div class="col-lg-2">
        <div class="form-group">
          <label class="col-form-label">Tipo veículo</label><span class="text-danger"> *</span>
          <select v-model="obj.Veiculo.TipoVeiculo"
                  class="form-control form-control-sm"
                  @change="consultarMarcas()">

            <option value="1">
              Carro
            </option>
            <option value="2">
              Moto
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <div class="form-group">
            <label class="col-form-label">Placa</label><span class="text-danger"> *</span>
            <the-mask :mask="['AAA-#X##']"  v-model="obj.Veiculo.Placa" class="form-control form-control-sm placa" @change.native="placaChanged" />
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <div class="form-group">
            <label class="col-form-label">Kilometragem</label><span class="text-danger"> *</span>
            <the-mask :mask="['######']" type="tel" v-model="obj.Veiculo.KM" class="form-control form-control-sm" />
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <div class="form-group">
            <label class="col-form-label">Renavam</label>
            <the-mask :mask="['############']" type="tel" v-model="obj.Veiculo.Renavam" class="form-control form-control-sm" />
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label class="col-form-label">Passagem por leilão</label>
          <div class="checkbox-custom chekbox-primary">
            <input name="Veiculo.Leilao" id="Veiculo.Leilao" value="true" type="checkbox" v-model="obj.Veiculo.Leilao">
            <label for="Veiculo.Leilao"></label>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <div class="form-group">
            <label asp-for="Veiculo.TipoLeilao" class="col-form-label">Tipo Leilão</label><span class="text-danger" v-if="obj.Veiculo.Leilao"> *</span>
            <select v-model="obj.Veiculo.TipoLeilao"
                    class="form-control form-control-sm"
                    :disabled="!obj.Veiculo.Leilao">
              <template v-for="(i,$index) in tipoLeilao">
                <option :key="i" :value="$index">{{i}}</option>

              </template>
            </select>
          </div>
        </div>
      </div>

    </div>
    <div class="row form-group ml-0 mr-0">
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">Marca</label><span class="text-danger"> *</span>
          <v-select v-model="obj.Veiculo.FIPEMarca"
                    :options="marcas"
                    label="name"
                    append-to-body
                    @input="consultarModelos"
                    :reset-on-options-change="true"><span slot="no-options">Busca não encontrada</span></v-select>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label class="col-form-label">Modelo</label><span class="text-danger"> *</span>
          <v-select v-model="obj.Veiculo.FIPEModelo"
                    :options="modelos"
                    label="name"
                    append-to-body
                    @input="consultarAnos"
                    :reset-on-options-change="true"><span slot="no-options">Busca não encontrada</span></v-select>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">Ano Modelo</label><span class="text-danger"> *</span>
          <v-select v-model="obj.Veiculo.FIPEAno"
                    :options="anos"
                    label="name"
                    append-to-body
                    @input="consultarValor"
                    :reset-on-options-change="true"><span slot="no-options">Busca não encontrada</span></v-select>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label class="col-form-label">Ano Fabricação</label><span class="text-danger"> *</span>
          <the-mask :mask="['####']" type="tel" v-model="obj.Veiculo.AnoFab" class="form-control form-control-sm" />
        </div>
      </div>
    </div>
    <div class=" row form-group ml-0 mr-0">
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">Tabela REF. FIPE</label><span class="text-danger"> *</span>
          <input type="text" class="form-control form-control-sm" :disabled="true" v-model="obj.TabelaREFFipe" />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">Valor FIPE</label><span class="text-danger"> *</span>
          <money v-model="obj.ValorFipe"
                 class="form-control form-control-sm"
                 id="ValorFipe"
                 name="ValorFipe" :disabled="true"></money>
        </div>
      </div>
      
    </div>

    <div class=" row form-group ml-0 mr-0">
      <div class="col-lg-3">
        <div class="form-group">
          <div class="form-group">
            <label>Cor</label><span class="text-danger"> *</span>
            <select v-model="obj.Veiculo.CorId"
                    asp-for="Veiculo.CorId"
                    class="form-control form-control-sm"
                    @change="($event) => updateAttr($event,'CorId')">
              <template v-for="i in attrsVeiculo.cor">
                <option :key="i.Id" :value="i.Id">{{i.Descricao}}</option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <div class="form-group">
            <label asp-for="Veiculo.CombustivelId">Combustível</label><span class="text-danger"> *</span>
            <select v-model="obj.Veiculo.CombustivelId"
                    class="form-control form-control-sm"
                    @change="($event) => updateAttr($event,'CombustivelId')">
              <template v-for="i in attrsVeiculo.combustivel">
                <option :value="i.Id" :key="i.Id">{{i.Descricao}}</option>
              </template>

            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <div class="form-group">
            <label>Carroceria</label><span class="text-danger"> *</span>
            <select v-model="obj.Veiculo.CarroceriaId"
                    class="form-control form-control-sm"
                    @change="($event) => updateAttr($event,'CarroceriaId')">
              <template v-for="i in attrsVeiculo.carroceria">
                <option :value="i.Id" :key="i.Id">{{i.Descricao}}</option>

              </template>
            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <div class="form-group">
            <label>Transmissão</label><span class="text-danger"> *</span>
            <select v-model="obj.Veiculo.TransmissaoId"
                    class="form-control form-control-sm"
                    @change="($event) => updateAttr($event,'TransmissaoId')">
              <template v-for="i in attrsVeiculo.transmissao">
                <option :value="i.Id" :key="i.Id">{{i.Descricao}}</option>
              </template>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="form-divider"></div>
    <h4 class="text-primary">
      Opcionais
    </h4>
    <div class="row form-group ml-0 mr-0 row-opcionais">
      <template v-for="opcional in attrsVeiculo.opcionais">
        <div class="col-lg-3" :key="opcional.Id">
          <div class="form-group mb-1">
            <div class="checkbox-custom checkbox-primary">
              <input type="checkbox" :value="opcional" :id="'opcional-'+opcional.OpcionalId" v-model="obj.Opcional">
              <label :for="'opcional-'+opcional.OpcionalId">{{ opcional.Descricao }}</label>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>
<script>
  import { tipoLeilao } from '@/services/enums.js';
  import api from '@/services/api.js';
  export default {
    data() {
        const that = this;
        return {
          tipoLeilao: tipoLeilao,
          marcas: [],
          modelos: [],
          anos: [],
          opcionais: [],//opcionais,
          cor: [],
          combustivel: [],
          transmissao: [],
          carroceria: [],
          CorId: '',
          CombustivelId: '',
          RransmissaoId: '',
          CarroceriaId: '',

        };
    },
    name: 'VeiculoCreatePartial',
    props: ['obj','attrsVeiculo'],
    methods: {
      consultarMarcas: function () {
          const obj = this.obj;
          let that = this;
        Fipe.GetMarcas(obj.Veiculo.TipoVeiculo).then(response => {
            
          response.json().then(data => {
            that.marcas = data;
              that.modelos = [];
              that.anos = [];

              obj.TabelaREFFipe = '';
              obj.ValorFipe = '';

              obj.Veiculo.Modelo.Descricao = '';
              obj.Veiculo.Modelo.Marca.Descricao = '';
              obj.Veiculo.AnoMod = '';
          });
                
        });
      },
      consultarModelos: function (val) {
        if (val) {
          let that = this;
            const obj = this.obj;

          Fipe.GetModelos(obj.Veiculo.TipoVeiculo, val.id).then(response => {
            response.json().then(data => {
              that.modelos = data;
                that.anos = [];

                obj.TabelaREFFipe = '';
                obj.ValorFipe = '';

                obj.Veiculo.Modelo.Marca.Descricao = val.name;
                obj.Veiculo.Modelo.Descricao = '';
                obj.Veiculo.AnoMod = '';
            });
                
          });
        }
            
        },
      consultarAnos: function (val) {
          let that = this;
          if (val && val.id) {
              const obj = that.obj;

            Fipe.GetVersoes(obj.Veiculo.TipoVeiculo, obj.Veiculo.FIPEMarca.id, obj.Veiculo.FIPEModelo.id, val.id).then(response => {
              response.json().then(data => {
                that.anos = data;

                  obj.TabelaREFFipe = '';
                  obj.ValorFipe = '';

                  obj.Veiculo.Modelo.Descricao = val.name;
                  obj.Veiculo.AnoMod = '';
              });
                    
            });
          }
      },
      consultarValor: function (val) {
          if (val && val.id) {
              const obj = this.obj;

            Fipe.GetDetalhes(obj.Veiculo.TipoVeiculo, obj.Veiculo.FIPEMarca.id, obj.Veiculo.FIPEModelo.id, val.id).then(response => {
              response.json().then(data => {
                  obj.TabelaREFFipe = data.referencia;
                  obj.ValorFipe = data.preco;
                  obj.Veiculo.TabelaREFFipe = data.referencia;
                obj.Veiculo.ValorFipe = parseFloat(data.preco.replace('R$ ', '')
                  .replace(/\./g, '')
                  .replace(/\,/g, '.'));
                  obj.Veiculo.AnoMod = val.name;
              });
                    
              });
          }
      },
      updateAttr: function ($event, prop) {
          this.obj[prop] = $($event.target).find('[value="' + $event.target.value + '"]').text();
      },
      placaChanged($event) {
        let that = this;
        let placa = $event.target.value.replace(/[^0-9a-zA-Z]+/g, '');
        if (placa.length === 7) {
          api.get('/veiculos/consultar?q='+placa)
          .then(response => {
            let result = response.data;
            if (result && result.length) {
              result = result[0];
              that.pushMarca(result.Modelo.Marca);
              that.pushModelo(result.Modelo);
              that.pushAno(result);
              that.obj.Opcional = [];

              for (var i in result.Opcional) {
                let obj = that.opcionais.filter(o => o.OpcionalId == result.Opcional[i].OpcionalId)[0];
                if (obj) {
                  that.obj.Opcional.push(obj);
                }
              }
              that.obj.TabelaREFFipe = result.TabelaREFFipe;
              that.obj.ValorFipe = result.ValorFipe;

              that.$nextTick(() => {
                that.obj.Veiculo = result;

              });
            }
          });
        }
      },
      pushMarca(marca) {
        this.marcas.push({name: marca.Descricao, id: marca.Id});
      },
      pushModelo(modelo) {
        this.modelos.push({name: modelo.Descricao, id: modelo.Id});
      },
      pushAno(veiculo) {
        this.anos.push({name: veiculo.AnoMod, id: veiculo.Modelo.CodigoVersao});
      },

    },
    beforeMount: function () {

        let that = this;
        api.get('/avaliacoes/create').then(function (response) {
            let { opcionais, checklists, cor, combustivel, transmissao, carroceria } = response.data;
            that.opcionais = opcionais;
            that.checklists = checklists;
            that.cor = cor;
            that.combustivel = combustivel;
            that.transmissao = transmissao;
            that.carroceria = carroceria;
        });

    }

  };
</script>
