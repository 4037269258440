<template>
  <div>
    <h4 class="text-primary">Dados Pessoais</h4>
    <div class="row form-group ml-0 mr-0">
      <div class="col-lg-3">
        <div class="form-group">
          <div class="form-group">
            <label class="col-form-label">CPF/CNPJ</label>
            <the-mask :mask="['###.###.###-##', '##.###.###/####-##']" @change.native="documentChanged" type="tel" v-model="params.Cliente.CpfCnpj" class="form-control form-control-sm" />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="col-form-label">Nome/Razão Social</label><span class="text-danger"> *</span>
          <input type="text" v-model="params.Cliente.Nome" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">RG/Inscrição estadual</label>
          <the-mask type="tel" v-model="params.Cliente.RGIE" :mask="['##.###.###-##']" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="col-form-label">Apelido/Nome fantasia</label>
          <input type="text" v-model="params.Cliente.ApelidoRazaoSocial" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="col-form-label">E-mail</label>
          <input type="email" v-model="params.Cliente.Email" class="form-control form-control-sm" />
        </div>
      </div>
    </div>
    <h4 class="text-primary">Endereço</h4>
    <div class="row row-form ml-0 mr-0">
      <div class="col-lg-2">
        <div class="form-group">
          <label class="col-form-label">CEP</label>
          <input @blur="buscaCEP(params.Cliente.Endereco[0].CEP, cepCallback)"
                 type="tel"
                 v-model="params.Cliente.Endereco[0].CEP"
                 class="form-control form-control-sm"
                 v-mask="'#####-###'" />

        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label class="col-form-label">Bairro</label>
          <input type="text"
                 class="form-control form-control-sm"
                 v-model="params.Cliente.Endereco[0].Bairro" />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label class="col-form-label">Logradouro</label>
          <input type="text"
                 class="form-control form-control-sm"
                 v-model="params.Cliente.Endereco[0].Logradouro" />
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label class="col-form-label">Número</label>
          <input type="text"
                 class="form-control form-control-sm"
                 v-model="params.Cliente.Endereco[0].Numero" />
        </div>
      </div>

    </div>
    <div class="row form-group ml-0 mr-0">
      <div class="col-lg-2">
        <div class="form-group">
          <label class="col-form-label">Complemento</label>
          <input type="text"
                 class="form-control form-control-sm"
                 v-model="params.Cliente.Endereco[0].Complemento" />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">Estado</label>
          <v-select v-model="estadoSelected"
                    :options="estados"
                    append-to-body
                    label="Descricao"
                    @input="(val) => consultaCidade(val)"><span slot="no-options">Busca não encontrada</span></v-select>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label class="col-form-label">Cidade</label>
          <v-select v-model="params.Cliente.Endereco[0].Cidade"
                    :options="cidades"
                    label="Descricao"
                    append-to-body
                    @input="(val) => cidadeChange(val)"><span slot="no-options">Busca não encontrada</span></v-select>
        </div>
      </div>
    </div>
    <div class="form-divider"></div>
    <h4 class="text-primary">
      Contato
      <button title="Incluir novo contato"
              type="button"
              class="novo btn btn-sm btn-default ml-1"
              @click="params.Cliente.Contato.splice(0,0,{ TipoContato: 0, Tratamento: 0 })">
        <i class="fas fa-plus" style="color: inherit;"></i>
      </button>
    </h4>
    <div class="row form-group ml-0 mr-0" style="max-height: 110px; overflow:auto">
      <div class="col-lg-12">
        <table class="table table-sm table-bordered table-striped">
          <thead>
            <tr>
              <th>Tratar com <span class="text-danger">* </span></th>
              <th>Tratamento</th>
              <th>Tipo</th>
              <th>Setor</th>
              <th>Número <span class="text-danger">* </span></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item) in params.Cliente.Contato">
              <tr :key="item.Id">
                <td>
                  <input type="text" class="form-control form-control-sm" v-model="item.TratarCom" />
                </td>
                <td>
                  <select v-model="item.Tratamento"
                          class="form-control form-control-sm">
                    <template v-for="(i,$index) in tratamento">
                      <option :key="$index" :value="$index">
                        {{i}}
                      </option>
                    </template>
                  </select>
                </td>
                <td>
                  <select v-model="item.TipoContato"
                          class="form-control form-control-sm">
                    <template v-for="(i,$index) in tipoContato">
                      <option :key="$index" :value="$index">
                        {{i}}
                      </option>
                    </template>
                  </select>
                </td>
                <td>
                  <input type="text" v-model="item.Setor" class="form-control-sm form-control" />
                </td>
                <td>
                  <the-mask :mask="['(##) ####-####', '(##) #####-####']" type="tel" v-model="item.Telefone" class="form-control-sm form-control" />
                </td>
                <td>
                  <button title="Excluir contato" type="button" class="novo btn btn-sm btn-danger" @click="params.Cliente.Contato.splice(params.Cliente.Contato.indexOf(item),1)">
                    <i class="fas fa-trash" style="color: inherit;"></i>
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>
<script>
  import api from '@/services/api.js';
  import { tratamento, tipoContato } from '@/services/enums.js';  
  export default {
      data() {
        return {
          tratamento: tratamento,
          tipoContato: tipoContato,
          estados: [],
          estadoSelected: null,
          cidades: []

        };
      },
      methods: {
        buscaCEP: window.buscaCEP,
        cepCallback: function (dados) {
          let endereco = this.params.Cliente.Endereco[0];
          if (!("erro" in dados)) {
            //Atualiza os campos com os valores da consulta.
            let estado = this.estados.filter(e => e.Sigla === dados.uf)[0];
            document.novaCidade = dados.localidade;
            if (estado) {
                this.estadoSelected = estado;
            }
            endereco.Bairro = dados.bairro;
            endereco.Logradouro = dados.logradouro;
            this.$nextTick(() => {
              this.consultaCidade(estado);
            })
          }
        },
        consultaCidade: function (estado) {
            let that = this;
            if (estado && estado.Id) {
                api
                    .get('/parametros/cidades?id=' + estado.Id)
                    .then(response => {
                        var endereco = that.params.Cliente.Endereco[0];
                        if (response.data && response.data.length) {
                            that.cidades = response.data;
                            if (endereco) {
                                endereco.Cidade = null;
                            }
                            if (document.novaCidade) {
                                var cidade = that.cidades.filter(e => e.Descricao === document.novaCidade)[0];
                                if (cidade) {
                                  endereco.Cidade = cidade;
                                  endereco.CidadeId = cidade.Id;
                                }
                                document.novaCidade = null;
                            }
                        } else {
                            that.cidades = [];
                            that.params.Cliente.CidadeEstado = '';
                        }
                    });

            } else {
                this.cidades = [];
            }

        },
        cidadeChange: function (val) {

              if (val && val.Id) {
                  this.params.Cliente.Endereco[0].CidadeId = val.Id;
                  this.params.Cliente.CidadeEstado = val.Descricao + '/' + val.Estado.Sigla;
              }
        },
        documentChanged($event) {
          let that = this;
          let doc = $event.target.value.replace(/[^0-9]+/g, '');

          api.get('/configuracoes/ConsultarPessoa?q='+doc)
            .then(response => {
              let result = response.data;
              if (result && result.Records.length) {
                that.params.Cliente = result.Records[0];
                that.estadoSelected = that.params.Cliente.Estado;
                document.novaCidade = that.params.Cliente.Endereco[0].Cidade ? that.params.Cliente.Endereco[0].Cidade.Descricao : null;

              }
            });
        }
      },
      props: ['params'],
      beforeMount: function () {
          let that = this;
          this.$nextTick(function () {
              api.get('/parametros/estados')
                  .then(response => {
                    that.estados = response.data;
                    that.estadoSelected = that.params.Cliente.Estado;
                  });
          });
      }
  };
</script>
